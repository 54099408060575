function errorElement(message) {
  const errorEle = document.createElement('p');
  errorEle.className = 'font-bold text-red-700 text-sm';
  errorEle.innerText = message;
  return errorEle;
}

function alertMessage(
  title = 'Error',
  message = '',
  type = '',
  buttonText = null,
  callback = null,
) {
  let icon = '';
  let iconBg = '';
  let input = null;
  let btnBgColor = 'bg-blue-500';
  let btnHoverBgColor = 'blue-600';
  let btnFocusRingColor = 'blue-300';
  if (message === '') {
    message = title;
    title = '';
  }
  switch (type) {
    case 'success':
      iconBg = 'bg-green-100';
      icon = 'fa-regular fa-circle-check text-green-600';
      btnBgColor = 'bg-green-600';
      btnHoverBgColor = 'green-700';
      btnFocusRingColor = 'green-500';
      break;
    case 'warning':
      iconBg = 'bg-yellow-50';
      icon = 'fa-solid fa-triangle-exclamation text-yellow-600';
      btnBgColor = 'bg-yellow-600';
      btnHoverBgColor = 'yellow-700';
      btnFocusRingColor = 'yellow-500';
      break;
    case 'error':
      iconBg = 'bg-red-100';
      icon = 'fa-solid fa-exclamation text-red-700';
      btnBgColor = 'bg-red-700';
      btnHoverBgColor = 'red-400';
      btnFocusRingColor = 'red-400';
      break;
    case 'info':
      iconBg = 'bg-blue-100';
      icon = 'fa-solid fa-exclamation text-blue-600';
      break;
    case 'question':
      iconBg = 'bg-blue-100';
      icon = 'fa-solid fa-question text-blue-600';
      break;
    case 'input':
      iconBg = 'bg-blue-100';
      icon = 'fa-solid fa-question text-blue-600';
      input =
        '<input id="alertInput" type="text" class="p-2 rounded-md m-2 border w-full"></input>';
      break;
    default:
  }
  const callbackBtn = `<button id="confirmBtn" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 ${btnBgColor} text-base font-medium text-white hover:bg-${btnHoverBgColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${btnFocusRingColor} sm:w-auto sm:text-sm">${buttonText}</button>`;
  const div = document.createElement('div');
  div.className = 'fixed z-[999] inset-0 overflow-y-auto';
  div.innerHTML = `<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${iconBg} sm:mx-0 sm:h-10 sm:w-10">
          <i class="${icon} text-xl "></i>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">${title}</h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">${message}</p>
            ${input === null ? '' : input}
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex sm:space-x-3">
        ${buttonText === null ? '' : callbackBtn}
        <button id="closeToastBtn" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 sm:mt-0 sm:w-auto sm:text-sm">${
          buttonText === null ? 'Okay' : 'Cancel'
        }</button>
      </div>
    </div>
  </div>`;
  div.querySelector('#closeToastBtn').addEventListener('click', () => {
    div.remove();
  });
  document.querySelector('body').appendChild(div);
  if (buttonText !== null && callback !== null) {
    document.getElementById('confirmBtn').addEventListener('click', () => {
      div.remove();
      callback();
    });
  } else if (callback !== null) {
    setTimeout(() => {
      div.remove();
      callback();
    }, 3000);
  }
}

function nonDismissableAlertMessage(
  title = 'Error',
  message = 'An error occurred!',
  buttonText = 'OK',
  callback = () => {},
) {
  let icon = 'fa-solid fa-exclamation text-yellow-700';
  let iconBg = 'bg-yellow-100';
  const btnBgColor = 'bg-yellow-700';
  const btnHoverBgColor = 'yellow-800';
  const btnFocusRingColor = 'yellow-500';

  if (message === '') {
    message = title;
    title = 'Error';
  }

  const callbackBtn = `<button id="confirmBtn" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 ${btnBgColor} text-base font-medium text-white hover:bg-${btnHoverBgColor} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${btnFocusRingColor} sm:w-auto sm:text-sm">${buttonText}</button>`;
  const div = document.createElement('div');
  div.className = 'fixed z-[999] inset-0 overflow-y-auto';
  div.innerHTML = `<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
      <div class="sm:flex sm:items-start">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${iconBg} sm:mx-0 sm:h-10 sm:w-10">
          <i class="${icon} text-xl "></i>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">${title}</h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">${message}</p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex sm:space-x-3">
        ${callbackBtn}
      </div>
    </div>
  </div>`;
  document.querySelector('body').appendChild(div);

  document.getElementById('confirmBtn').addEventListener('click', () => {
    div.remove();
    callback();
  });
}

function toastMessage(message, type = 'info', title = '') {
  let typeClass = '';
  let icon = '';

  switch (type) {
    case 'success':
      icon = 'fa-solid fa-check-circle text-green-400';
      typeClass = 'border-green-200 bg-emerald-50 text-green-900';
      break;
    case 'warning':
      icon = 'fa-solid fa-triangle-exclamation text-yellow-400';
      typeClass = 'border-yellow-300 bg-yellow-50 text-yellow-900';
      break;
    case 'error':
      icon = 'fa-solid fa-circle-xmark text-red-400';
      typeClass = 'border-red-200 bg-red-100 text-red-900';
      break;
    default:
      icon = 'fa-solid fa-circle-info text-blue-400';
      typeClass = 'border-blue-200 bg-blue-50 text-blue-900';
  }

  let toastGroupElement = document.querySelector('#toastGroup');

  if (toastGroupElement === null) {
    toastGroupElement = document.createElement('div');
    toastGroupElement.id = 'toastGroup';
    toastGroupElement.className =
      'fixed pointer-events-none z-[999] inset-0 pt-2 h-auto max-w-sm w-auto ml-auto';
    document.querySelector('body').appendChild(toastGroupElement);
  }

  if (toastGroupElement.childElementCount >= 5) {
    toastGroupElement.children[0].remove();
  }

  let toastMessageElement = document.createElement('div');

  toastMessageElement.className =
    'flex items-end px-4 py-1 pointer-events-none sm:items-start';
  toastMessageElement.innerHTML = `<div class="w-full flex flex-col items-center sm:items-end">
        <div
          class="w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden ${typeClass}"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <i
                  class="${icon} h-5 w-5 text-2xl"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">${title}</p>
                <p class="mt-1 text-sm text-gray-500">${message}</p>
              </div>
              <div id="btnGrp" class="ml-4 flex-shrink-0 flex">
                <button
                  id="closeToastBtn"
                  class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <i class="fa-solid fa-xmark h-5 w-5" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>`;

  toastMessageElement
    .querySelector('#closeToastBtn')
    .addEventListener('click', () => {
      toastMessageElement.remove();
      toastMessageElement = null;
      if (toastGroupElement.childElementCount === 0) {
        toastGroupElement.remove();
        toastGroupElement = null;
      }
    });

  toastGroupElement.appendChild(toastMessageElement);

  setTimeout(() => {
    if (toastGroupElement) {
      toastMessageElement.remove();
      if (toastGroupElement.childElementCount === 0) {
        toastGroupElement.remove();
      }
    }
  }, 4000);
}

const buttonLoader = {
  element: null,
  create(ele) {
    this.element = ele;
    return this;
  },
  start(msg = '') {
    if (msg !== '') {
      this.element.dataset.oldtext = this.element.innerText;
      this.element.innerText = msg;
    }
    const spinner = document.createElement('i');
    spinner.setAttribute('id', 'buttonLoaderSpinner');
    spinner.classList.add(
      'fa-solid',
      'fa-circle-notch',
      'ml-2',
      'animate',
      'animate-spin',
    );
    this.element.disabled = true;
    this.element.classList.add(
      'disabled:bg-neutral-400',
      'disabled:text-white',
      'pointer-events-none',
      'cursor-wait',
    );
    this.element.appendChild(spinner);
  },
  stop() {
    if (this.element.dataset.oldtext && this.element.dataset.oldtext !== '') {
      this.element.innerText = this.element.dataset.oldtext;
      this.element.dataset.oldtext = '';
    }
    this.element.disabled = false;
    this.element.classList.remove(
      'disabled:bg-gray-900',
      'disabled:text-white',
      'pointer-events-none',
      'cursor-wait',
    );
    const spinner = document.querySelector('#buttonLoaderSpinner');
    spinner.remove();
  },
};

function serializeObject(form) {
  let object = {};
  const formData = new FormData(form);

  formData.forEach(function (value, key) {
    if (!Reflect.has(object, key)) {
      object[key] = value;
      return;
    }

    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }

    object[key].push(value);
  });

  return object;
}

function removeByAttr(arr, attr, value) {
  let i = arr.length;
  while (i > 0) {
    i -= 1;
    if (
      arr[i] &&
      Object.prototype.hasOwnProperty.call(arr[i], attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }

  return arr;
}

/**
 * Handles Axios errors thrown by any
 * */
function handleAxiosError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    console.error(error.response);

    if (error.response.status === 404) {
      window.location = '/404';
    } else if (error.response.status === 503) {
      alertMessage(
        'Under Maintenance',
        'We are making some improvements to the platform. Please check back later.',
        'warning',
      );
    } else if (error.response.data) {
      alertMessage(
        error.response.statusText ||
          error.response.data.message ||
          'Unknown Error',
        error.response.data.exception ||
          error.response.data.detail ||
          'Something went wrong. Please try again.',
        'error',
      );
    }
  } else if (error.request) {
    // The request was made but no response was received
    // 'error.request' is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(error);
    alertMessage(
      'Client Error',
      'The client application has encountered an unexpected issue.',
      'error',
    );
  }
}

/**
 * Highlights an element for a short span
 */
function highlightme(seconds, color = '#FCF8E3') {
  const element = document.getElementById(this.id);
  const origcolor = element.style.backgroundColor;
  element.style.backgroundColor = color;
  setTimeout(() => {
    element.style.backgroundColor = origcolor;
  }, seconds * 1000);
}

/**
 * Get query string values
 * https://stackoverflow.com/a/901144/2971041
 */
function jsGup(name, url) {
  let urlParamCopy = url;
  let nameParamCopy = name;

  if (!url) urlParamCopy = window.location.href;

  nameParamCopy = nameParamCopy.replace(/[[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${nameParamCopy}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlParamCopy);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * Get the Content Length based on the median value
 */
function getContentLength(length) {
  switch (length) {
    case '27.5':
      return '25-30 words';
    case '90':
      return '80-100 words';
    case '150':
      return '100-200 words';
    case '250':
      return '200-300 words';
    case '425':
      return '350-500 words';
    case '600':
      return '500-700 words';
    case '900':
      return '800-1000 words';
    case '1100':
      return '1000-1200 words';
    case '1500':
      return '1400-1600 words';
    case '2000':
      return 'Around 2000 words';
    case '2500':
      return 'Around 2500 words';
    case '3000':
      return 'Around 3000 words';
    default:
      return 'N/A';
  }
}

/**
 * Format the string as Indian Number system format
 */
function indianNumberFormat(x) {
  let xParamCopy = x;

  xParamCopy = x.toString();

  let afterPoint = '';

  if (xParamCopy.indexOf('.') > 0)
    afterPoint = xParamCopy.substring(
      xParamCopy.indexOf('.'),
      xParamCopy.length,
    );

  xParamCopy = Math.floor(xParamCopy);
  xParamCopy = xParamCopy.toString();

  let lastThree = xParamCopy.substring(xParamCopy.length - 3);
  const otherNumbers = xParamCopy.substring(0, xParamCopy.length - 3);

  if (otherNumbers !== '') {
    lastThree = `,${lastThree}`;
  }

  return (
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint
  );
}

/**
 * Sets the query string for any URL
 */
function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, `$1${key}=${value}$2`);
  }
  return `${uri + separator + key}=${value}`;
}

/**
 * Checks if a string is valid JSON
 */
function isValidJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

function formSentenceFromArray(arr) {
  return arr
    .reduce(
      (prev, curr, i) => prev + curr + (i === arr.length - 2 ? ' and ' : ', '),
      '',
    )
    .slice(0, -2);
}

function clearFlashedErrorMsgs() {
  const errorMessages = document.querySelectorAll('.flashed-error-msgs');
  errorMessages.forEach((el) => el.remove());
}

function flashErrorNearInput(error) {
  clearFlashedErrorMsgs();

  if (error.response) {
    if (error.response.status === 422) {
      Object.keys(error.response.data.errors).forEach((keyName) => {
        if (
          Object.prototype.hasOwnProperty.call(
            error.response.data.errors,
            keyName,
          )
        ) {
          Object.keys(error.response.data.errors[keyName]).forEach((item) => {
            if (
              Object.prototype.hasOwnProperty.call(
                error.response.data.errors[keyName],
                item,
              )
            ) {
              let p = document.createElement('p');
              p.classList.add('text-sm', 'text-red-700', 'flashed-error-msgs');
              p.textContent = `${error.response.data.errors[keyName][item]}`;

              document
                .querySelector(`[data-vid="${keyName}"]`)
                ?.insertAdjacentElement('afterend', p);
            }
          });
        }
      });
    } else {
      handleAxiosError(error);
    }
  } else {
    handleAxiosError(error);
  }
}

function trimStr(string) {
  if (!string) return;

  if (string.length > 60) {
    return `${string.slice(0, 60)}...`;
  }
  return string;
}

/**
 * Normalizes AI response objects that may or may not have a 'values' wrapper or a 'data' wrapper
 * @param {string} response - The AI response object to normalize
 * @returns {Object} - The normalized AI response object
 */
function normalizeAIJsonResponse(response) {
  const jsonResponse = JSON.parse(response);

  if (
    jsonResponse &&
    typeof jsonResponse === 'object' &&
    'values' in jsonResponse
  ) {
    if (
      jsonResponse.values &&
      typeof jsonResponse.values === 'object' &&
      'data' in jsonResponse.values
    ) {
      return jsonResponse.values.data;
    }

    return jsonResponse.values;
  }

  return jsonResponse;
}

export {
  trimStr,
  buttonLoader,
  isValidJson,
  updateQueryStringParameter,
  indianNumberFormat,
  getContentLength,
  formSentenceFromArray,
  jsGup,
  highlightme,
  serializeObject,
  handleAxiosError,
  removeByAttr,
  alertMessage,
  nonDismissableAlertMessage,
  toastMessage,
  errorElement,
  clearFlashedErrorMsgs,
  flashErrorNearInput,
  normalizeAIJsonResponse,
};
