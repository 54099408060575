export default [
  {
    path: '/tasks/:id',
    name: 'CustomerDocumentEditor',
    component: () => import('@/views/customer/DocumentEditor.vue'),
  },
  {
    path: '/quick-order/:id',
    name: 'PlaceQuickOrder',
    component: () => import('@/views/customer/quick-order/PlaceQuickOrder.vue'),
  },
  {
    path: '/getting-started',
    name: 'Onboarding',
    component: () => import('@/views/customer/Onboarding.vue'),
  },
  {
    path: '',
    name: 'CustomerLayout',
    component: () => import('@/views/customer/Layout.vue'),
    children: [
      {
        path: '/customer/home',
        name: 'CustomerHome',
        component: () => import('@/views/customer/Home.vue'),
      },
      {
        path: '/notifications',
        name: 'CustomerNotifications',
        component: () => import('@/views/customer/ViewNotifications.vue'),
      },
      {
        path: '/activities',
        name: 'CustomerActivities',
        component: () => import('@/views/customer/ViewActivities.vue'),
      },
      {
        path: '/orders/new/:id',
        name: 'CustomerPlaceOrder',
        component: () => import('@/views/customer/PlaceOrder.vue'),
      },
      {
        path: '/orders/new',
        name: 'CustomerCreateOrder',
        component: () => import('@/views/customer/CreateOrder.vue'),
      },
      {
        path: '/orders/:id',
        name: 'CustomerViewOrder',
        component: () => import('@/views/customer/ViewOrder.vue'),
      },
      {
        path: '/orders',
        name: 'CustomerViewOrders',
        component: () => import('@/views/customer/ViewOrders.vue'),
      },
      {
        path: '/cart',
        name: 'CustomerViewCart',
        component: () => import('@/views/customer/ViewCart.vue'),
      },
      {
        path: '/projects',
        name: 'CustomerViewProjects',
        component: () => import('@/views/customer/ViewProjects.vue'),
      },
      {
        path: '/projects/:id',
        name: 'CustomerViewProject',
        component: () => import('@/views/customer/ViewProject.vue'),
      },
      {
        path: '/tasks',
        name: 'CustomerViewTasks',
        component: () => import('@/views/customer/ViewTasks.vue'),
      },
      {
        path: '/settings',
        name: 'CustomerSettings',
        component: () => import('@/views/customer/Settings.vue'),
      },
      {
        path: '/settings/:path',
        name: 'CustomerSettingsWithPath',
        component: () => import('@/views/customer/Settings.vue'),
      },
      {
        path: '/invoices',
        name: 'CustomerInvoices',
        component: () => import('@/views/customer/Invoices.vue'),
      },
      {
        path: '/link-company/:company_id/invitations/:token',
        name: 'CustomerLinkCompany',
        component: () => import('@/views/customer/LinkCompany.vue'),
      },
      {
        path: '/customer/revision-tracker',
        name: 'CustomerRevisionTracker',
        component: () => import('@/views/customer/RevisionTracker.vue'),
      },
      {
        path: '/customer/first-submissions-tracker',
        name: 'CustomerFirstSubmissionsTracker',
        component: () => import('@/views/customer/FirstSubTracker.vue'),
      },
      {
        path: '/blogs',
        name: 'CustomerBlogs',
        component: () => import('@/views/customer/Blogs.vue'),
      },
      {
        path: '/blogs/:id',
        name: 'CustomerBlogEditor',
        component: () => import('@/views/customer/BlogEditor.vue'),
        children: [
          {
            path: '/blogs/:id/brief',
            name: 'CustomerBlogEditorBriefGenerator',
            component: () =>
              import('@/components/customer/blog-editor/BriefGenerator.vue'),
          },
          {
            path: '/blogs/:id/outline',
            name: 'CustomerBlogEditorOutlineGenerator',
            component: () =>
              import('@/components/customer/blog-editor/OutlineGenerator.vue'),
          },
        ],
      },
      {
        path: '/campaigns',
        name: 'CustomerCampaigns',
        component: () => import('@/views/customer/Campaigns.vue'),
      },
      {
        path: '/campaigns/:id',
        name: 'CustomerCampaign',
        component: () => import('@/views/customer/Campaign.vue'),
      },
      {
        path: '/calendar',
        name: 'CustomerCalendar',
        redirect: '/calendar/month',
      },
      {
        path: '/calendar/month/:year?/:month?',
        name: 'CustomerCalendarMonthView',
        component: () => import('@/views/customer/calendar/MonthView.vue'),
      },
      {
        path: '/calendar/week/:year?/:month?/:date?',
        name: 'CustomerCalendarWeekView',
        component: () => import('@/views/customer/calendar/WeekView.vue'),
      },
      {
        path: '/social-media-posts',
        name: 'CustomerSocialMediaPosts',
        component: () => import('@/views/customer/SocialMediaPosts.vue'),
      },
      {
        path: '/social-media-posts/:id',
        name: 'CustomerSocialMediaPostEditor',
        component: () => import('@/views/customer/SocialMediaPostEditor.vue'),
      },
      {
        path: '/email-newsletters',
        name: 'CustomerEmailNewsletters',
        component: () => import('@/views/customer/EmailNewsletters.vue'),
      },
      {
        path: '/email-newsletters/:id',
        name: 'CustomerEmailNewsletterEditor',
        component: () => import('@/views/customer/EmailNewsletterEditor.vue'),
      },
      {
        path: '/seo-landing-pages',
        name: 'CustomerSEOLandingPages',
        component: () => import('@/views/customer/SEOLandingPages.vue'),
      },
      {
        path: '/seo-landing-pages/:id',
        name: 'CustomerSEOLandingPageEditor',
        component: () => import('@/views/customer/SEOLandingPageEditor.vue'),
      },
      {
        path: '/website-contents',
        name: 'CustomerWebsiteContents',
        component: () => import('@/views/customer/WebsiteContents.vue'),
      },
      {
        path: '/website-contents/:id',
        name: 'CustomerWebsiteContentEditor',
        component: () => import('@/views/customer/WebsiteContentEditor.vue'),
      },
      {
        path: '/video-scripts',
        name: 'CustomerVideoScripts',
        component: () => import('@/views/customer/VideoScripts.vue'),
      },
      {
        path: '/video-scripts/:id',
        name: 'CustomerVideoScriptEditor',
        component: () => import('@/views/customer/VideoScriptEditor.vue'),
      },
      {
        path: '/ai-tools',
        name: 'CustomerAiToolsLayout',
        component: () => import('@/views/customer/ai-tools/Layout.vue'),
        children: [
          {
            path: '/ai-tools/brief-generator',
            name: 'CustomerAiToolsBriefGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/brief-generator/BriefGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/briefs/:id',
            name: 'CustomerAiToolsViewBrief',
            component: () =>
              import('@/views/customer/ai-tools/brief-generator/ViewBrief.vue'),
          },
          {
            path: '/ai-tools/video-script-generator',
            name: 'CustomerAiToolsVideoScriptGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/video-script/VideoScriptGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/video-scripts/:id',
            name: 'CustomerAiToolsViewVideoScript',
            component: () =>
              import(
                '@/views/customer/ai-tools/video-script/ViewVideoScript.vue'
              ),
          },
          {
            path: '/ai-tools/content-calendar-generator',
            name: 'CustomerAiToolsCalendarGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/content-calendar/CalendarGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/content-calendars/:id',
            name: 'CustomerAiToolsViewCalendar',
            component: () =>
              import(
                '@/views/customer/ai-tools/content-calendar/ViewCalendar.vue'
              ),
          },
          {
            path: '/ai-tools/meta-title-description-generator',
            name: 'CustomerAiToolsMetaTitleDescriptionGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/meta-title-description-generator/MetaTitleDescriptionGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/meta-title-descriptions/:id',
            name: 'CustomerAiToolsViewMetaTitleDescription',
            component: () =>
              import(
                '@/views/customer/ai-tools/meta-title-description-generator/ViewMetaTitleDescription.vue'
              ),
          },
          {
            path: '/ai-tools/content-idea-generator',
            name: 'CustomerAiToolsContentIdeaGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/content-idea/ContentIdeaGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/content-ideas/:id',
            name: 'CustomerAiToolsViewContentIdea',
            component: () =>
              import(
                '@/views/customer/ai-tools/content-idea/ViewContentIdea.vue'
              ),
          },
          {
            path: '/ai-tools/twitter-thread-generator',
            name: 'CustomerAiToolsTwitterThreadGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/twitter-thread/TwitterThreadGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/twitter-threads/:id',
            name: 'CustomerAiToolsViewTwitterThread',
            component: () =>
              import(
                '@/views/customer/ai-tools/twitter-thread/ViewTwitterThread.vue'
              ),
          },
          {
            path: '/ai-tools/linkedin-post-generator',
            name: 'CustomerAiToolsLinkedinPostGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/linkedin-post/LinkedinPostGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/linkedin-posts/:id',
            name: 'CustomerAiToolsViewLinkedinPost',
            component: () =>
              import(
                '@/views/customer/ai-tools/linkedin-post/ViewLinkedinPost.vue'
              ),
          },
          {
            path: '/ai-tools/linkedin-carousel-generator',
            name: 'CustomerAiToolsLinkedinCarouselGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/linkedin-carousel/LinkedinCarouselGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/linkedin-carousel/:id',
            name: 'CustomerAiToolsViewLinkedinCarousel',
            component: () =>
              import(
                '@/views/customer/ai-tools/linkedin-carousel/ViewLinkedinCarousel.vue'
              ),
          },
          {
            path: '/ai-tools/blog-outline-generator',
            name: 'CustomerAiToolsBlogOutlineGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/blog-outline/BlogOutlineGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/blog-outlines/:id',
            name: 'CustomerAiToolsViewBlogOutline',
            component: () =>
              import(
                '@/views/customer/ai-tools/blog-outline/ViewBlogOutline.vue'
              ),
          },
          {
            path: '/ai-tools/email-newsletter-generator',
            name: 'CustomerAiToolsEmailNewsletterGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/email-newsletter/EmailNewsletterGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/email-newsletters/:id',
            name: 'CustomerAiToolsViewEmailNewsletter',
            component: () =>
              import(
                '@/views/customer/ai-tools/email-newsletter/ViewEmailNewsletter.vue'
              ),
          },
          {
            path: '/ai-tools/seo-landing-page-generator',
            name: 'CustomerAiToolsSeoLandingPageGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/seo-landing-page-generator/SeoLandingPageGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/seo-landing-pages/:id',
            name: 'CustomerAiToolsViewSeoLandingPage',
            component: () =>
              import(
                '@/views/customer/ai-tools/seo-landing-page-generator/ViewSeoLandingPage.vue'
              ),
          },
          {
            path: '/ai-tools/website-content-generator',
            name: 'CustomerAiToolsWebsiteContentGenerator',
            component: () =>
              import(
                '@/views/customer/ai-tools/website-content-generator/WebsiteContentGenerator.vue'
              ),
          },
          {
            path: '/ai-tools/website-contents/:id',
            name: 'CustomerAiToolsViewWebsiteContent',
            component: () =>
              import(
                '@/views/customer/ai-tools/website-content-generator/ViewWebsiteContent.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/wordpress-setup',
    name: 'CustomerWordPressSetup',
    component: () => import('@/views/customer/WordPressSetup.vue'),
  },
];
