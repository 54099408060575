import { createRouter, createWebHistory } from 'vue-router';

import adminRoutes from '@/routes/admin.routes';
import applicantRoutes from '@/routes/applicant.routes';
import customerRoutes from '@/routes/customer.routes';
import superadminRoutes from '@/routes/superadmin.routes';
import guestRoutes from '@/routes/guest.routes';
import writerRoutes from '@/routes/writer.routes';
import commonRoutes from '@/routes/common.routes';
import authUserStore from './stores/authUserStore';
import accountantRoutes from './routes/accountant.routes';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes: [
    ...adminRoutes,
    ...applicantRoutes,
    ...commonRoutes,
    ...customerRoutes,
    ...guestRoutes,
    ...superadminRoutes,
    ...writerRoutes,
    ...accountantRoutes,
    {
      path: '/:pathMatch(.*)*',
      name: '404Page',
      component: () => import('@/views/errors/404.vue'),
    },
  ],
});

// Force refresh the page on every production build (`vite build`)
// Error: https://stackoverflow.com/a/74861436/2971041
router.onError((error, to) => {
  if (error.message) {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed') ||
      error.message.includes('Load failed')
    ) {
      window.location = to.fullPath;
    }
  } else if (import.meta.env.MODE !== 'production') {
    console.error('Error caught in router.onError:', error);
  }
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta.isGuestRoute) {
    const store = authUserStore();
    const isLoggedIn = await store.checkIfLoggedIn().then((result) => result);

    if (!isLoggedIn) {
      store.intendedUrl = to.path;
      next('/login');
      return;
    } else {
      if (store.userData.user_type === 'writer') {
        if (
          !store.userData.is_registration_complete &&
          to.name !== 'WriterCompleteRegistration' &&
          to.name !== 'Unsubscribe' &&
          to.name !== 'VerifyEmail'
        ) {
          next('/writer/complete-registration');
          return;
        }

        if (
          store.userData.is_registration_complete &&
          to.name === 'WriterCompleteRegistration'
        ) {
          next(`/${store.userData.user_type}/home`);
          return;
        }
      }

      if (store.userData.user_type === 'customer') {
        if (
          to.name === 'VerifyEmail' ||
          to.name === 'PlaceQuickOrder' ||
          to.name === 'CustomerViewOrder' ||
          to.name === 'CustomerLinkCompany'
        ) {
          next();
          return;
        }

        if (
          store.userData.is_email_verified &&
          !store.userData.preferences.is_company_onboarded &&
          to.name !== 'Onboarding'
        ) {
          next({ path: '/getting-started', query: { redirect: to.fullPath } });
          return;
        }

        if (
          store.userData.preferences.is_company_onboarded &&
          to.name === 'Onboarding'
        ) {
          next('/customer/home');
          return;
        }
      }

      if (!store.userData.is_email_verified && to.name !== 'VerifyEmail') {
        window.location.href = '/verify-email';
      }

      if (store.userData.is_email_verified && to.name === 'VerifyEmail') {
        next(`/${store.userData.user_type}/home`);
        return;
      }

      if (to.path === '/' || to.path === '/home') {
        next(`/${store.userData.user_type}/home`);
        return;
      }
    }
  }

  next();
});

router.afterEach(async () => {
  const store = authUserStore();
  const isLoggedIn = await store.checkIfLoggedIn().then((result) => result);

  if (isLoggedIn && window.clarity && import.meta.env.MODE === 'production') {
    window.clarity(
      'identify',
      store.userData.email,
      null,
      null,
      store.userData.full_name,
    );
  }
});

export default router;
